<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M2.20824194,2.20791771 C2.47195548,1.92090719 2.9627627,1.94065682 3.23002359,2.20791771 C3.23390131,2.21179633 3.2374279,2.21532202 3.24095359,2.21884771 C3.50998364,2.478707 3.76984293,2.74773705 4.03534369,3.01323781 C4.58396462,3.56185874 5.13117744,4.10907156 5.67804725,4.65594137 L5.67804725,4.65594137 L6.001,4.978 L8.77030063,2.20963272 C9.02958634,1.95031418 9.4232377,1.94306178 9.70962549,2.14336094 L9.79208229,2.20963272 C10.0790928,2.47334626 10.0593432,2.96415348 9.79208229,3.23141437 L9.79208229,3.23141437 L9.78115229,3.24234436 C9.521293,3.51137442 9.25226295,3.77123371 8.98676219,4.03673447 C8.43814126,4.5853554 7.89092844,5.13256821 7.34405863,5.67943803 L7.34405863,5.67943803 L7.023,6 L9.13694062,8.11483474 L9.13694062,8.11483474 L9.79240633,8.77030045 C10.0776567,9.05551474 10.0579071,9.50331145 9.79240633,9.79208211 C9.52869279,10.0790926 9.03788557,10.059343 8.77062468,9.79208211 C8.76674606,9.78820403 8.76322037,9.78467807 8.75969469,9.78115211 C8.49066463,9.52129282 8.23080534,9.25226276 7.96530458,8.98676201 L7.96530458,8.98676201 L6.32260102,7.34405845 L6.32260102,7.34405845 L6.001,7.023 L3.5574324,9.46606425 L3.5574324,9.46606425 L3.22969955,9.79379702 C2.94448526,10.0790474 2.49668855,10.0592978 2.20791771,9.79379702 C1.92090719,9.53008357 1.94065682,9.03927635 2.20791771,8.77201546 L2.20791771,8.77201546 L2.21884771,8.76108546 C2.47870718,8.49205541 2.74773724,8.23219612 3.01323799,7.96669536 L3.01323799,7.96669536 L4.65594155,6.3239918 C4.76369527,6.21623808 4.87139648,6.10853687 4.9790631,6.00087025 L4.42887495,5.45033238 C3.90706225,4.92851968 3.3855384,4.40699583 2.86370765,3.88516508 L2.86370765,3.88516508 L2.20824194,3.22969937 C1.92299155,2.94448508 1.94274118,2.49668837 2.20824194,2.20791771 Z"
    />
  </svg>
</template>
